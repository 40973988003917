<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
      <filter-toko
       v-model:value="customers"
       :mode="null"
       class="mr-2"
       >
       </filter-toko>
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="m-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>

    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button title="Tambah Artikel" type="primary" @click="showModal">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <!-- <a-button
          class="ml-2"
          type="primary"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button> -->
      </div>
    </div>

    <div class="table-responsive ">
      <md-table
        row-key="id"
        :columns="columns"
        :data-source="data"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span> {{ startRow + index }} </span>
        </template>
        <template #created="{ text }">
          <span>{{ text }}</span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#5cb85c">active</a-tag>
          <a-tag v-else color="#fd6464">inactive</a-tag>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Data">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="lihat(record, true)"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Ubah Data">
              <a
                href="javascript: void(0);"
                class="btn  btn-sm btn-light"
                @click="edit(record, false)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus Data">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="postDelete(record.id)"
              >
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
    <m-modal
      v-if="visible"
      v-model:visible="visible"
      :model="model"
      @handleOk="handleOk"
      :destroy-on-close="true"
    ></m-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import _columns from './columns'
import qs from 'qs'
import apiClient from '@/services/axios'
import FilterToko from '@/components/filter/FilterToko'
import { Modal, message } from 'ant-design-vue'
import MModal from './modal'

import moment from 'moment'

const initial = () => ({
  id: null,
  title: null,
  content: null,
  active: null,
  is_public: null,
  created_by: null,
  created_at: null,
  updated_by: null,
  updated_at: null,
  is_deleted: null,
  highlight: null,
  cf1: null,
  is_all_user: null,
  categories: null,
})

export default defineComponent({
  components: { MModal, FilterToko },
  emits: ['handleOk'],
  setup() {
    const state = reactive({
      data: [],
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },
      page: 1,
      isFetching: false,
      isDownloading: false,
      search: null,
      q: null,
      params: [],
    })

    const visible = ref(false)
    const formState = reactive({ ...initial() })

    const handleChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
    }

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const startRow = ref(1)

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const model = reactive({ readOnly: false })

    const showModal = () => {
      model.record = reactive({ ...initial() })
      model.readOnly = false
      visible.value = true
    }

    const handleOk = form => {
      visible.value = false
      fetchData()
    }

    const params = ref({})
    const columns = ref([])
    columns.value = _columns
    const errorMessage = ref()

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        q: state.q,
        active: formState.active,
      }
      state.params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/articles', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          state.data = items
          state.meta = _meta || { pageCount: 0, totalCount: 0 }
          state.pageCount = state.meta.pageCount
          state.totalCount = state.meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/articles', {
          params: {
            ...state.params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `artikel_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const postDelete = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus',
        content: 'Hapus data ini ?',
        onOk() {
          apiClient
            .delete(`/api/articles/${id}`)
            .then(response => {
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }

    const lihat = (record, readOnly = false) => {
      visible.value = true
      model.record = record
      model.readOnly = readOnly
    }

    const edit = (record, readOnly = false) => {
      visible.value = true
      model.record = record
      model.readOnly = readOnly
    }
    const search = () => {
      fetchData()
    }
    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      startRow,
      visible,
      showModal,
      handleOk,
      columns,
      handleChange,
      handleTableChange,
      model,
      lihat,
      edit,
      ...toRefs(formState),
      ...toRefs(state),
      fetchXlsx,
      postDelete,
      search,
      errorMessage,
    }
  },
})
</script>

<style scoped>
.card-body {
  margin-left: 20px;
}
</style>

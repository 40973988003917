export default [
  {
    title: 'No',
    slots: {
      customRender: 'no',
    },
  },
  {
    title: 'Judul',
    dataIndex: 'title',
  },
  {
    title: 'Pengguna',
    dataIndex: 'null',
  },
  {
    title: 'Tanggal Pembuatan',
    dataIndex: 'created_at',
    slots: { customRender: 'created' },
  },
  {
    title: 'Status',
    dataIndex: 'active',
    slots: { customRender: 'status' },
  },
  {
    title: 'Status Publik',
    dataIndex: 'is_public',
    slots: { customRender: 'status' },
  },
  {
    title: 'Kategori',
    dataIndex: 'categories',
  },
  {
    title: 'Prioritas',
    dataIndex: 'highlight',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
    // align: 'center',
  },
]
